export const PathStrConst = {
  signin: "/",
  signup: "/registration",
  editregistration: "/editregistration",
  forgotPassword: "/forgotPassword",
  resetPassword: "/resetPassword/:token",
  verification: "/verification",
  generalInformation: "/generalInfo",
  bankAccount: "/home",
  bankAccountCards: "/bankAccountCards",
  convert: "/convert",
  sendFunds: "/sendFunds",
  leftSideBar: "/leftSideBar",
  rightSideBar: "/rightSideBar",
  pieGraph: "/piegraph",
  addbeneficiary: "/addbeneficiary",
  PrivacyPolicy: "/privacyPolicy",
  OmwFees: "/OMWfees",
  downloadPdf: "/download",
  invoice: '/invoice',
  interestaccount: '/interestaccount',
  statement: '/statement',
  CardPayments: '/cardPayments',
  CompleteRegistration: '/completeRegistration/:token',
};

// B4B Transaction Types
// DEBIT (amount deducted from card balance)
export const DebitedTransactionCodes = ["unload", "financial", "authorisation",]
export const CreditedTransactionCodes = ["load", "financial_reversal", "authorisation_reversal"]